import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useLangContext } from '../../context/lang.context'
import Section from '../../components/Section'
import Headline from '../../components/Headline'
import Button from '../../components/Button'
import { Autoplay, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation';
import 'swiper/css/pagination'
import 'swiper/css/autoplay'

const OpiniePrzetargi = ({ title, desc }) => {
  const { lang } = useLangContext()
  const results = useStaticQuery(graphql`
    {
      testimonials: allContentfulReferencje(filter: {node_locale: {eq: "pl"}}) {
        nodes {
          author
          blockquote {
            childMarkdownRemark {
              html
            }
          }
          position
          node_locale
        }
      }
    }
  `)

  const testimonials = results.testimonials.nodes;
  return (
    <Section top mobile bottomXS>
      <Headline h={2} title={title} desc={desc} large gradient />
      <Swiper
        modules={[Autoplay, Navigation]}
        navigation
        spaceBetween={30}
        slidesPerView={1}
        // autoplay={{ delay: 3000 }}
        breakpoints={{
          767: {
            slidesPerView: 1.5,
          },
          1199: {
            slidesPerView: 2.15,
          },
        }}
        css={sTestimonialSwiper}>
          {testimonials.map((testimonial, id) => (
            <SwiperSlide key={id}>
              <div css={sTestimonialBox}>
                <div css={sTestimonialBoxText} dangerouslySetInnerHTML={{ __html: testimonial.blockquote.childMarkdownRemark.html }} />
                <div css={sTestimonialBoxDetails}>
                  {/* <Button noMargin glow action={`#`}>
                    Czytaj artykuł
                  </Button> */}
                  <div css={sTestimonialBoxAuthorWrapper}>
                    <span css={sTestimonialBoxAuthor}>{testimonial.author}</span>
                    <span css={sTestimonialBoxAuthorPosition}>{testimonial.position}</span>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </Section>
  )
}

const sTestimonialBoxes = {
  marginTop: '4rem',
}

const sTestimonialSwiper = {
  display: 'flex',
  width: '100%',
  marginTop: '4rem',
  overflow: 'visible',
  '.swiper-slide': {
    position: 'relative',
    height: 'auto',
    // background: 'linear-gradient(90deg, #474747 33%, #202020 100%)',
    background: '#fff',
    borderRadius: '32px',
    padding: '2rem 2.5rem',
    boxShadow: '0 0 15px 0 rgba(107, 181, 58, .2)',
    '&::before': {
      content: '""',
      position: 'absolute',
      inset: 0,
      padding: '2px',
      background: 'linear-gradient(196.27deg, rgba(49, 124, 0, .7) 0%, rgba(49, 124, 0, .7) 30%, #6BB53A 50%, rgba(107, 181, 58, .7) 85%)',
      WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      WebkitMaskComposite: 'xor',
      maskComposite: 'exclude',
      opacity: 1,
      borderRadius: '32px',
    }
  },
  '.swiper-button-prev, .swiper-button-next': {
    background: '#6BB53A',
    width: '44px',
    height: '44px',
    borderRadius: '50%',
    color: '#fff',
    '&:before, &:after': {
      fontSize: '18px',
      fontWeight: 800
    }
  }
}

const sTestimonialBox = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
  // padding: '2rem 2.5rem',
  // background: 'linear-gradient(90deg, #474747 33%, #202020 100%)',
  // borderRadius: '32px',
}

const sTestimonialBoxText = {
  color: '#2b2a29',
  // opacity: '.6',
  fontSize: '16px',
  lineHeight: '28px',
  '&:before, &:after': {
    content: '"\\""',
    position: 'relative',
    display: 'inline',
    fontSize: '32px',
    fontWeight: '800',
    color: '#2b2a29'
    // background:
    //   'linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0.16) 100%)',
    // WebkitBackgroundClip: 'text',
    // WebkitTextFillColor: 'transparent',
    // backgroundClip: 'text',
    // textFillColor: 'transparent',
  },
  '&>*': {
    display: 'inline',
  }
  // span: {
  //   fontSize: '32px',
  //   fontWeight: '800',
  //   background:
  //     'linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0.16) 100%)',
  //   WebkitBackgroundClip: 'text',
  //   WebkitTextFillColor: 'transparent',
  //   backgroundClip: 'text',
  //   textFillColor: 'transparent',
  // },
}

const sTestimonialBoxDetails = {
  paddingTop: '2rem',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  gap: '25px',
}

const sTestimonialBoxAuthorWrapper = {
  display: 'inline-flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  textAlign: 'right',
}

const sTestimonialBoxAuthor = {
  fontSize: '24px',
  fontWeight: 400,
  color: '#2b2a29'
  // background:
  //   'linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.16) 100%)',
  // WebkitBackgroundClip: 'text',
  // WebkitTextFillColor: 'transparent',
  // backgroundClip: 'text',
  // textFillColor: 'transparent',
}

const sTestimonialBoxAuthorPosition = {
  color: '#2b2a29',
  opacity: '.3',
  fontSize: '13px',
  fontWeight: 600,
  textTransform: 'uppercase',
}

export default OpiniePrzetargi
